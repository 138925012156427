import { useQuery } from "@tanstack/vue-query";
import { useApi } from "~/composables/useApi";
import camelize from "camelcase-keys";

export const nfcQueries = {
  detail: (nfcId: MaybeRef<string>) => ({
    queryKey: ["nfc", nfcId, "detail"],
    queryFn: async () => {
      const api = useApi();
      const data = await api<NfcResponse>(`/v1/nfc/getNfc/${toValue(nfcId)}`);
      return camelize(data, { deep: true });
    },
  }),
  memberShirts: (memberId: MaybeRef<number>) => ({
    queryKey: ["memberShirts", memberId],
    queryFn: async () => {
      const api = useApi();
      const data = await api<NfcData[]>(`/v1/nfc/memberShirts`);
      return camelize(data, { deep: true });
    },
  }),
};

export const useNfcQuery = (id: string) => {
  return useQuery(nfcQueries.detail(id));
};

type Metrics = {
  impact: number;
  donated: number;
};

type AdditionalMetric = {
  impact: number;
  donated: number;
  contributors: number;
};

export type NfcData = {
  id: number;
  member_id: number;
  campaign_id: number;
  dollar_value: number;
  position: number;
  created_at: string;
  updated_at: string;
  nfc_id: string;
  member_name: string;
  own_metrics: Record<string, Metrics>;
  additional_metrics: Record<string, AdditionalMetric>;
};

type NfcResponse = {
  hasBeenClaimed: boolean;
  nfc_data: NfcData | null;
};
